import { User } from '../user';

export const getUserRole = ({ roles }: User) => {
  if (roles?.admin) {
    return 'admin';
  }

  if (roles?.editor) {
    return 'editor';
  }

  return 'user';
};
